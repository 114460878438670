import React, { Children } from "react"
import tw, { styled, theme } from "twin.macro"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper/modules"

const arrowTransform = (angle, x, y) => `
  i:first-child {
    transform: translate(${x}px, ${y}px) rotate(${angle}deg);
  }

  i:last-child {
    transform: translate(${x}px, ${-y}px) rotate(${-angle}deg);
  }
`

const Styles = styled.div`
  ${tw`mx-auto l:max-w-l relative`}

  .swiper-slide {
    ${tw`h-auto`}
  }

  .swiper-button-next,
  .swiper-button-prev {
    ${tw`hidden l:block absolute cursor-pointer border-0 bg-transparent`}

    &:after {
      ${tw`hidden`}
    }

    -webkit-appearance: none;
    outline: none;

    &:focus {
      outline: none;
    }

    top: calc(50% - 20px);
    width: 30px;
    height: 30px;
    transform: translate3d(0, 0, 0);

    i {
      ${tw`absolute left-0 bg-white`}

      top: 40%;
      width: 30px;
      height: 4px;
      border-radius: 2px;

      transition: all 0.15s ease;

      @media (min-width: 1280px) {
        ${tw`bg-grey-35`}
      }
    }

    &.left {
      ${tw`left-4`}

      @media (min-width: 1280px) {
        ${tw`-left-12`}
      }

      i {
        transform-origin: 0% 50%;
      }

      ${arrowTransform(40, 0, -1)}

      &:hover {
        ${arrowTransform(30, 0, -1)}
      }

      &:active {
        ${arrowTransform(25, 1, -1)}
      }

      &.swiper-button-disabled {
        ${arrowTransform(0, -5, 0)}

        &:hover {
          ${arrowTransform(0, -5, 0)}
        }
      }
    }

    &.right {
      ${tw`right-4`}

      @media (min-width: 1280px) {
        ${tw`-right-12`}
      }

      i {
        transform-origin: 100% 50%;
      }

      ${arrowTransform(40, 0, 1)}

      &:hover {
        ${arrowTransform(30, 0, 1)}
      }

      &:active {
        ${arrowTransform(25, 1, 1)}
      }

      &.swiper-button-disabled {
        ${arrowTransform(0, 5, 0)}

        &:hover {
          ${arrowTransform(0, 5, 0)}
        }
      }
    }

    &.swiper-button-disabled {
      ${tw`opacity-30 cursor-default`}
    }

    &.swiper-button-lock {
      ${tw`hidden`}
    }
  }

  .swiper-pagination {
    bottom: -50px;
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-inactive-color: ${theme`colors.priority.darkP2`};
    --swiper-pagination-color: ${theme`colors.grey.35`};
    --swiper-pagination-bullet-horizontal-gap: 8px;
  }

  &.dark {
    .swiper-pagination {
      --swiper-pagination-bullet-inactive-color: ${theme`colors.grey.35`};
      --swiper-pagination-color: ${theme`colors.priority.darkP2`};
    }

    .swiper-button-next,
    .swiper-button-prev {
      i {
        @media (min-width: 1280px) {
          ${tw`bg-grey-95`}
        }
      }

      &.swiper-button-disabled {
        ${tw`opacity-50`}
      }
    }
  }
`

const CarouselSwiper = ({ children, params, className = "", id = "" }) => {
  const selectorPrefix = `${Styles}${id ? `#${id}` : ""}`

  const baseParams = {
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: `${selectorPrefix} .swiper-button-next`,
      prevEl: `${selectorPrefix} .swiper-button-prev`,
      enabled: false,
    },
    pagination: {
      el: `${selectorPrefix} .swiper-pagination`,
      type: "bullets",
      clickable: true,
    },
  }

  const swiperParams = {
    ...baseParams,
    ...params,
  }

  return (
    <Styles className={className} id={id || null}>
      <Swiper {...swiperParams}>
        {Children.map(children, (Child) => (
          <SwiperSlide>
            <Child.type {...Child.props} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-pagination"></div>

      <div className="swiper-button-prev left">
        <i></i>
        <i></i>
      </div>
      <div className="swiper-button-next right">
        <i></i>
        <i></i>
      </div>
    </Styles>
  )
}

export default CarouselSwiper
