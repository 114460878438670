import React from "react"
import tw, { styled } from "twin.macro"
import ConditionalWrapper from "components/conditional-wrapper"

const PressCardStyle = styled.div`
  ${tw`my-2 px-2 w-full m:w-1/2 l:w-1/3 flex`}

  .header {
    ${tw`pt-4`}
    background-image: ${(props) => (props.bg ? `url(${props.bg})` : false)};
    background-position-y: top;
    background-size: cover;
    background-size-y: 160px;
    background-repeat: no-repeat;
    height: 240px;
    position: relative;
  }

  .logo {
    ${tw`p-4 rounded-full flex items-center justify-center border-white border-2 z-10`}
    background-color: rgba(0, 0, 0, 0.85);
    position: absolute;
    top: 80px;
    height: 160px;
    left: 50%;
    margin-left: -80px;
    width: 160px;
  }

  .quote {
    ${tw`p-6 font-body text-grey-10 text-center text-xxs`}
    fontSize: 11px;
    lineheight: 20px;
    @media (min-width: 640px) {
      font-size: 15px;
      line-height: 27px;
    }
  }

  .cover {
    ${tw`flex flex-col items-center justify-center overflow-hidden`}
    max-height: 160px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.carousel {
    ${tw`m:w-full l:w-full h-full my-0 px-0`}

    .cover {
      img {
        ${tw`object-cover w-full h-full`}
      }
    }
  }
`

export default ({ press, className = "" }) => {
  if (press) {
    return (
      <PressCardStyle className={className}>
        <ConditionalWrapper
          condition={press.linkUrl}
          wrapper={(children) => (
            <a
              href={press.linkUrl}
              rel="noopener noreferrer"
              target="_blank"
              className="flex flex-grow"
            >
              {children}
            </a>
          )}
        >
          <div className="bg-white flex flex-col flex-grow rounded">
            <div className="header">
              {press.featuredImage && (
                <div className="cover">
                  <img src={press.featuredImage?.fixed?.src} />
                </div>
              )}
              <div className="logo">
                {press.publicationLogo && (
                  <img src={press.publicationLogo.url} alt="Publication logo" />
                )}
              </div>
            </div>
            <div className="flex flex-col flex-grow justify-between pb-4">
              <div
                className="quote"
                dangerouslySetInnerHTML={{ __html: press.excerpt.html }}
              />
            </div>
          </div>
        </ConditionalWrapper>
      </PressCardStyle>
    )
  } else {
    return <></>
  }
}
