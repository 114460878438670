import React from "react"
import { styled } from "twin.macro"

const Arrow = styled.span`
  height: 1rem;
  width: 1rem;
  display: block;
  margin: 0;
  margin-left: 0.5rem;
  top: 3px;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  display: inline;

  &.large {
    @media (min-width: 640px) {
      margin-left: 1rem;
      top: 0.3rem;
    }
  }

  .left-bar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 10px;
    height: 2.5px;
    display: block;
    transform: rotate(35deg);

    border-radius: 2px;
  }
  .left-bar:after {
    content: "";
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
    width: 10px;
    height: 2.5px;
    display: block;

    border-radius: 6px 10px 10px 6px;
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    z-index: -1;
  }

  .right-bar {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 6.5px;
    width: 10px;
    height: 2.5px;
    display: block;
    transform: rotate(-35deg);

    border-radius: 2px;
  }
  .right-bar:after {
    content: "";
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
    width: 10px;
    height: 2.5px;
    display: block;

    border-radius: 10px 6px 6px 10px;
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    z-index: -1;
  }

  &.open .left-bar:after {
    background-color: ${(props) => props.activeColor ? props.activeColor : props.color};
    transform-origin: center center;
    transform: rotate(-70deg);
  }
  &.open .right-bar:after {
    background-color: ${(props) =>
      props.activeColor ? props.activeColor : props.color};
    transform-origin: center center;
    transform: rotate(70deg);
  }
`

const SwitchArrow = ({ active, activeColor, className, color = "white", ...rest }) => (
  <Arrow
    className={`${active ? "open" : ""} ${className ? className : ""}`}
    activeColor={activeColor}
    color={color}
    {...rest}
  >
    <span className="left-bar" />
    <span className="right-bar" />
  </Arrow>
)

export default SwitchArrow
