import React from "react"
import tw, { styled } from "twin.macro"

import PressCard from "components/press-card"
import CarouselSwiper from "components/carousel-swiper"

const Styles = styled.div`
  .title {
    ${tw`py-14 w-full font-display font-bold text-grey-10 text-center mx-auto l:max-w-l`}

    max-width: 90%;
    font-size: 30px;
    line-height: 35px;

    @media (min-width: 640px) {
      font-size: 45px;
      line-height: 53px;
    }

    @media (min-width: 1024px) {
      font-size: 50px;
      line-height: 56px;
    }
  }

  &.dark {
    .title {
      ${tw`text-white`}
    }
  }
`

const FeaturedPressCarousel = ({
  articles,
  title,
  className = "",
  id = "",
}) => {
  const swiperParams = {
    slidesPerView: 1.25,
    spaceBetween: 10,
    slidesOffsetBefore: 44,
    slidesOffsetAfter: 44,
    breakpoints: {
      640: {
        slidesPerView: 2.25,
        spaceBetween: 16,
        slidesOffsetBefore: 42,
        slidesOffsetAfter: 42,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 16,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        slidesPerGroup: 3,
        navigation: {
          enabled: true,
        },
      },
    },
  }

  return (
    <Styles className={className}>
      <h2 className="title">
        {title || "Driving industry thought leadership"}
      </h2>
      <CarouselSwiper params={swiperParams} id={id} className={className}>
        {articles.map((press) => (
          <PressCard key={press.id} press={press} className="carousel" />
        ))}
      </CarouselSwiper>
    </Styles>
  )
}

export default FeaturedPressCarousel
