import React, { useEffect, useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"
import { connectRefinementList } from "react-instantsearch-dom"

const Container = styled.div`
  ${tw`bg-grey-90 font-body text-grey-20 text-center`}
  box-shadow: inset -2px -2px 3px 0 rgba(110, 110, 110, 0.05),
    inset 2px 2px 3px 0 rgba(110, 110, 110, 0.05), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  letter-spacing: 0;
  font-size: 13px;
  line-height: 11px;
  position: sticky;
  top: 50px;
  z-index: 49;
  @media (min-width: 640px) {
    top: 80px;
  }
  @media (min-width: 1024px) {
    font-size: 15px;
    line-height: 13px;
  }
`

const CategoryFrame = styled.div`
  ${tw`p-4 flex flex-col`}
  background: ${(props) =>
    props.selected ? "linear-gradient(180deg, #e3284c 0%, #aa0024 100%)" : ""};
  box-shadow: ${(props) =>
    props.selected ? "0 0 5px 0 rgba(170, 0, 36, 0.66)" : ""};
  ${(props) => (props.selected ? "color: white;" : "")};
  clip-path: polygon(10px 0%, 100% 0%, calc(100% - 10px) 100%, 0% 100%);
  cursor: pointer;
  display: inline-block;
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
  transition: all 0.2s;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) =>
      props.selected
        ? ""
        : "linear-gradient(180deg, #cdcdcd 0%, #cdcdcd 100%)"};
    z-index: -1;
    transition: opacity 0.1s linear;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
`

const Category = ({ selected, to, children, ...rest }) => {
  return (
    <Link to={to} state={{ disableScrollUpdate: true }} {...rest}>
      <CategoryFrame selected={selected}>
        <div>{children}</div>
      </CategoryFrame>
    </Link>
  )
}

const InsightsCategories = connectRefinementList(
  ({ items, queryString, searchActive, id, onChange }) => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          insightsCategories(
            where: { slug_not: "announcements" }
            orderBy: name_ASC
          ) {
            name
            slug
          }
        }
      }
    `)
    const categories = data.gcms.insightsCategories

    function getSelectedSlug() {
      const item = items.find((i) => i.isRefined)
      const category = item
        ? categories.find((c) => c.name === item.label)
        : null
      return category ? category.slug : null
    }

    const [currentCategory, setCurrentCategory] = useState(null)

    useEffect(() => {
      setCurrentCategory(getSelectedSlug())
    }, [items])

    function getFilter(category) {
      return items.find((i) => i.label === category.name)
    }

    if (items.length > 0) {
      return (
        <Container id={id}>
          <Category
            onClick={(e) => {
              e && e.preventDefault()
              setCurrentCategory(null)
              onChange({
                attribute: "categories",
                refinement: null,
                applied: false,
                resetPage: true,
              })
            }}
            selected={currentCategory === null}
            to={`/insights/${queryString ? "?" : ""}${queryString}`}
          >
            All
          </Category>
          {categories.map((cat) => {
            const filter = getFilter(cat)
            if ((filter && filter.count === 0) || !filter) {
              return null
            } else {
              return (
                <Category
                  onClick={(e) => {
                    e && e.preventDefault()
                    setCurrentCategory(cat.slug)
                    onChange({
                      attribute: "categories",
                      refinement: cat.name,
                      applied: true,
                      resetPage: true,
                    })
                  }}
                  to={`/insights/category/${cat.slug}/${
                    queryString ? "?" : ""
                  }${queryString}`}
                  key={cat.name}
                  selected={currentCategory === cat.slug}
                >
                  {cat.name}
                  {searchActive && filter && filter.count && (
                    <span className="opacity-50">{` (${filter.count})`}</span>
                  )}
                </Category>
              )
            }
          })}
        </Container>
      )
    } else {
      return null
    }
  }
)

export default InsightsCategories
