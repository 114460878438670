import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"
import "styles/hubspot.css"
import HubspotForm from "react-hubspot-form"
import IconEmail from "images/icons/email.svg"
import IconCheck from "images/icons/check-circle-green.svg"
import { brandRules } from "utils/brand"
import ConditionalWrapper from "components/conditional-wrapper"
import BasicBackgroundSection from "components/basic-background-section"
import BgNewsletter from "images/bg/newsletter.jpg"

const Title = styled.div`
  ${tw`py-2 w-full font-display text-white text-center`}
  font-size: 20px;
  line-height: 30px;
  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 50px;
  }
`

const Slant = styled.div`
  clip-path: polygon(0% 10px, 100% 0%, 100% calc(100% - 10px), 0% 100%);
  @media (min-width: 640px) {
    clip-path: polygon(0% 45px, 100% 0%, 100% calc(100% - 45px), 0% 100%);
  }
`

const Content = styled.div`
  ${tw`py-16 max-w-s mx-auto m:max-w-m l:max-w-l`}

  ${({ slanted }) => (slanted ? tw`py-32` : "")}
`

const FormContainer = styled.div`
  .hs-form-required {
    display: none;
  }

  .hs-form {
    text-align: center;
  }

  legend.hs-field-desc {
    ${tw`py-2 font-light text-white text-center`}
    font-size: 18px;
    line-height: 25px;
    font-family: "Axiforma Light";
    font-weight: 200;
  }

  .hs-email input {
    background-image: url(${IconEmail});
    background-position: 10px 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding-left: 42px;
    width: 277px;
    @media (min-width: 640px) {
      width: 420px;
    }
  }

  .hs-email input:valid:not(.invalid) {
    background-image: url(${IconEmail}), url(${IconCheck});
    background-position: 10px 50%, right 10px top 50%;
    background-repeat: no-repeat, no-repeat;
    background-size: 20px 20px, 20px 20px;
  }

  li.hs-form-booleancheckbox {
    ${tw`mx-auto py-4`}
    border-top: 1px solid rgba(255, 255, 255, 0.14);
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);
    @media (min-width: 1024px) {
      width: 679px;
    }
  }

  .hs-form-booleancheckbox-display > input {
    -webkit-transform: scale(1.3);
    margin-bottom: -5px;
    vertical-align: text-top;
    background-color: #3498db;
    border-radius: 5px;
  }

  .hs-form-booleancheckbox-display > input:checked {
    box-shadow: 1px 1px 11px 0 rgba(52, 152, 219, 0.5);
  }

  .hs-form-booleancheckbox-display > span {
    ${tw`py-2 -mt-2 font-light text-white text-center`}

    line-height: 18px;
    font-family: "Axiforma Light";
    font-size: 12px;
    font-weight: 200;
    @media (min-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .hs-button.primary {
    ${tw`font-display text-white`}

    height: 40px;
    width: 122px;
    border-radius: 5px;
    background: linear-gradient(180deg, #3498db 0%, #006aa9 100%);
    box-shadow: 0 0 4px 0 rgba(0, 106, 169, 0.51);
    font-size: 15px;
    line-height: 12px;
    text-align: center;
  }

  .submitted-message {
    ${tw`py-2 font-light text-white text-center`}

    line-height: 25px;
    font-family: "Axiforma Light";
    font-size: 15px;
    font-weight: 200;
    @media (min-width: 1024px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
`

const NewsletterForm = ({ slanted = true, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      gcms {
        contentBlocks(where: { identifier: "insights-newsletter-form" }) {
          headerContent {
            html
          }
        }
      }
    }
  `)

  const { gcms } = data

  let headerText

  if (gcms.contentBlocks?.[0].headerContent?.html) {
    headerText = brandRules(gcms.contentBlocks[0].headerContent.html)
  } else {
    headerText =
      "Subscribe to expert TV commentary, SVOD industry analysis and global content demand trends:"
  }

  return (
    <ConditionalWrapper
      condition={slanted}
      wrapper={(children) => <Slant {...rest}>{children}</Slant>}
    >
      <BasicBackgroundSection
        image={BgNewsletter}
        color="#000000"
        bgPosition="center"
        size="cover"
        repeat="no-repeat"
      >
        <Content slanted={slanted}>
          <div className="l:w-10/12 mx-auto">
            <Title
              dangerouslySetInnerHTML={{
                __html: headerText,
              }}
            />
            <FormContainer>
              <HubspotForm
                portalId={process.env.GATSBY_HUBSPOT_ID}
                formId={process.env.GATSBY_HUBSPOT_NEWSLETTER_FORM}
                loading={<div>Loading...</div>}
                formInstanceId="newsletter-form"
              />
            </FormContainer>
          </div>
        </Content>
      </BasicBackgroundSection>
    </ConditionalWrapper>
  )
}

export default NewsletterForm
